<template>
  <form @submit.prevent="searchAddress" class="StoreFinderSearch">
    <div class="container">
      <div class="flex flex-wrap">
        <div class="w-full px-4 tablet:w-3/5 pb-4 tablet:pb-0">
          <div class="form-item">
            <input
              v-model="address"
              :placeholder="$t('components.stores.address')"
              aria-label="Your address"
              class="StoreFinderSearch__input StoreFinderSearch__form-element font-header text-xl leading-normal py-2 px-4 border border-solid border-gray-medium rounded-full w-full"
            />
          </div>
        </div>
        <div class="w-full px-4 tablet:w-1/5 pb-4 tablet:pb-0">
          <button
            v-html="$t('components.stores.search')"
            class="StoreFinderSearch__form-element block w-full button button--quaternary"
          />
        </div>
        <div class="w-full px-4 tablet:w-1/5 pb-4 tablet:pb-0">
          <button
            v-html="$t('components.stores.search_close')"
            @click="searchNearBy"
            type="button"
            class="StoreFinderSearch__form-element block w-full button button--quaternary"
          />
        </div>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  name: 'StoreFinderSearch',
  data() {
    return {
      address: null,
      google: undefined,
      geocoder: undefined
    }
  },
  async mounted() {
    try {
      this.google = await this.$gmapsInit()
      this.geocoder = new this.google.maps.Geocoder()
    } catch (error) {
      // Implement your own error handling here.
      console.error(error)
    }
  },
  methods: {
    searchAddress() {
      this.geocoder.geocode({ address: this.address }, (results, status) => {
        if (status !== 'OK' || !results[0]) return

        // Set address field to the address
        // found by the Google Maps API and
        // emit a search event with the found
        // coordinates.
        this.address = results[0].formatted_address
        this.$emit('search', {
          latitude: results[0].geometry.location.lat(),
          longitude: results[0].geometry.location.lng()
        })
      })
    },
    async searchNearBy() {
      const { latitude, longitude } = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          ({ coords }) => resolve(coords),
          // Reject if the user doesn't
          // allow accessing their location.
          (error) => reject(error)
        )
      })

      const latLng = new this.google.maps.LatLng(latitude, longitude)
      this.geocoder.geocode({ latLng }, (results, status) => {
        if (status !== 'OK' || !results[0]) return

        // Set address field to the address
        // found by the Google Maps API and
        // emit a search event with the users
        // coordinates.
        this.address = results[0].formatted_address
        this.$emit('search', {
          latitude: results[0].geometry.location.lat(),
          longitude: results[0].geometry.location.lng()
        })
      })
    }
  }
}
</script>
