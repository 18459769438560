var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"category product"},[_c('div',{staticClass:"category-content with-radius"},[(_vm.productData.acf.is_new && _vm.productData.acf.is_new === '1')?_c('span',{staticClass:"isNew"},[_c('span',{directives:[{name:"t",rawName:"v-t",value:('components.products.new'),expression:"'components.products.new'"}]})]):_vm._e(),_vm._v(" "),_c('nuxt-link',{attrs:{"to":_vm.localePath({
          name: 'collection-products-slug',
          params: {
            products: _vm.category,
            slug: _vm.productData.slug
          }
        })}},[_c('div',{staticClass:"relative w-full",staticStyle:{"height":"300px"}},[_c('img',{attrs:{"src":_vm.productData.media.medium,"alt":_vm.productData.title,"data-object-fit":"contain"}})])]),_vm._v(" "),_c('div',{staticClass:"category-text relative z-10"},[_c('h4',{domProps:{"innerHTML":_vm._s(_vm.productData.title)}}),_vm._v(" "),_c('nuxt-link',{directives:[{name:"t",rawName:"v-t",value:('components.products.more_information'),expression:"'components.products.more_information'"}],staticClass:"button button-green",attrs:{"to":_vm.localePath({
            name: 'collection-products-slug',
            params: {
              products: _vm.category,
              slug: _vm.productData.slug
            }
          })}}),_vm._v(" "),_c('nuxt-link',{staticClass:"button button-transparent",attrs:{"to":_vm.localePath({
            name: 'outlets',
            query: { product: _vm.productData.slug }
          })}},[_c('span',{directives:[{name:"t",rawName:"v-t",value:('components.products.find_outlet'),expression:"'components.products.find_outlet'"}]}),_vm._v(" "),_c('i',{staticClass:"fas fa-search fa-flip-horizontal float-right mt-2"})])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }