<template>
  <div>
    <section
      v-if="usps"
      :style="`background-image: url('${usps.media['post-thumbnail']}')`"
      class="bar_usps"
    >
      <div class="container">
        <div class="flex flex-row">
          <div class="w-full tablet:w-1/3 tablet:ml-auto">
            <div class="usp-block">
              <h3 v-html="usps.title" />
              <ul class="fa-ul">
                <li v-for="(usp, index) in usps.usps" :key="index">
                  <span class="fa-li"><i class="fas fa-check"></i></span
                  >{{ usp }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'BarsBar',
  data() {
    let usps = false
    const bars = this.$store.getters.getBars
    for (let i = 0; i < bars.length; i++) {
      if (bars[i].slug === 'bij-qopps') {
        usps = bars[i]
        usps.usps = usps.content.match(/<li>(.*?)<\/li>/g).map(function(val) {
          return val.replace(/<\/?li>/g, '')
        })
      }
    }
    return {
      usps
    }
  }
}
</script>
