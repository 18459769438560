<template>
  <main>
    <section id="top_slider" class="visuals category-header">
      <div class="container wide">
        <div class="flex flex-row">
          <div class="w-full">
            <div class="slide">
              <img :src="page.acf.header_image.sizes['2048x2048']" :alt="page.title" />

              <div class="big-button right bottom negative orange no-hover">
                <div>
                  <span v-t="'components.menu.contact'" class="big-text" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="home_content" class="py-12 tablet:py-20">
      <div class="container">
        <div class="flex flex-row">
          <div class="w-full tablet:w-2/5 px-4 tablet:px-2">
            <div class="flex flex-row">
              <div class="w-full tablet:text-right tablet:px-2">
                <h1 v-html="page.title" class="xl text-green" />
                <p class="text-left tablet:pl-48 mt-4">
                  <strong>Contactgegevens</strong><br />
                  Qopps<br />
                  Lagenheuvelstraat 11<br />
                  5408 RJ Volkel<br />
                  T: <a href="tel:0031413272914">0413 27 29 14</a><br />
                  E: <a href="mailto:info@qopps.nl">info@qopps.nl</a>
                </p>
              </div>
            </div>
          </div>
          <div class="w-full tablet:w-3/5 px-4 tablet:pt-2">
            <div v-html="page.content" class="tablet:pt-12" />
            <form @submit.prevent="submitForm" class="form mt-4">
              <div class="form_element">
                <input
                  v-model="first_name"
                  :placeholder="`${$t('form.first_name')} *`"
                  required
                  type="text"
                  class="form_input"
                />
              </div>
              <div class="form_element">
                <input
                  v-model="last_name"
                  :placeholder="`${$t('form.last_name')} *`"
                  required
                  type="text"
                  class="form_input"
                />
              </div>
              <div class="form_element">
                <input
                  v-model="phone"
                  :placeholder="`${$t('form.phone')}`"
                  type="tel"
                  class="form_input"
                />
              </div>
              <div class="form_element">
                <input
                  v-model="email_address"
                  :placeholder="`${$t('form.email_address')} *`"
                  required
                  type="email"
                  class="form_input"
                />
              </div>
              <div class="form_element">
                <textarea
                  v-model="message"
                  :placeholder="`${$t('form.message')} *`"
                  required
                  rows="7"
                  class="form_input"
                />
              </div>
              <div
                v-if="error_message !== ''"
                v-html="error_message"
                :class="
                  `bg-${
                    error_type === 'success' ? 'green' : 'orange-dark'
                  } text-white px-2 py-1 my-4`
                "
              />
              <div class="form_element text-right">
                <button
                  v-t="'form.send'"
                  type="submit"
                  class="button button-green"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
    <catalog-categories :shrink="true" />
    <bars-bar />
    <bars-outlets />
  </main>
</template>

<script>
import BarsBar from '../components/bars/Bar'
import wordpress from '../service/wordpress'
import CatalogCategories from '../components/catalog/Categories'
import BarsOutlets from '../components/bars/Outlets'
export default {
  name: 'Contact',
  components: { BarsOutlets, CatalogCategories, BarsBar },
  head() {
    const title =
      this.page.yoast.yoast_wpseo_title === ''
        ? this.page.title + ' • QOPPS • outdoor furniture •'
        : this.page.yoast.yoast_wpseo_title
    const description =
      this.page.yoast.yoast_wpseo_metadesc === ''
        ? this.page.excerpt.replace(/<(?:.|\n)*?>/gm, '')
        : this.page.yoast.yoast_wpseo_metadesc

    return {
      title,
      titleTemplate: title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: description
        },
        { property: 'og:locale', content: 'nl_NL' },
        { property: 'og:type', content: 'page' },
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
        {
          property: 'og:url',
          content: 'https://www.qopps.nl' + this.$route.fullPath
        },
        { property: 'og:site_name', content: 'QOPPS' },
        {
          property: 'og:article:published_time',
          content: this.page.date
        },
        {
          property: 'og:article:modified_time',
          content: this.page.date_modified
        },
        {
          property: 'og:article:updated_time',
          content: this.page.date_modified
        },
        {
          property: 'og:image',
          content: this.page.acf.header_image
            ? this.page.acf.header_image.sizes.medium
            : 'https://www.qopps.nl/images/logo2x.png'
        },
        {
          property: 'og:image:width',
          content: this.page.acf.header_image
            ? this.page.acf.header_image.sizes['medium-width']
            : 351
        },
        {
          property: 'og:image:height',
          content: this.page.acf.header_image
            ? this.page.acf.header_image.sizes['medium-height']
            : 65
        },
        { property: 'twitter:card', content: 'summary_large_image' },
        { property: 'twitter:description', content: description },
        { property: 'twitter:title', content: title },
        {
          property: 'twitter:image',
          content: this.page.acf.header_image
            ? this.page.acf.header_image.sizes.medium
            : 'https://www.qopps.nl/images/logo.png'
        }
      ]
    }
  },
  data() {
    return {
      first_name: '',
      last_name: '',
      phone: '',
      email_address: '',
      message: '',
      error_message: '',
      error_type: 'error'
    }
  },
  async asyncData({ query, app, store }) {
    let message = ''
    if (query.product) {
      const products = store.getters.getProducts
      let activeProduct = {}
      products.map((product) => {
        if (product.slug === query.product) {
          activeProduct = product
        }
      })
      message =
        app.i18n.t('components.products.question') +
        ': ' +
        activeProduct.title +
        (activeProduct.acf.sku ? ' (' + activeProduct.acf.sku + ')' : '')
    }
    const [page] = await Promise.all([wordpress.get(`page/contact`)])
    return {
      page: page.data,
      message
    }
  },
  mounted() {
    if (process.browser) {
      this.$gtag('event', 'your_event', {
        // page_title: this.product.title,
        page_path: this.$route.fullPath
      })
    }
  },
  methods: {
    validateEmail(email) {
      // eslint-disable-next-line no-useless-escape
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    },
    validateField(value) {
      return value.length > 0
    },
    submitForm() {
      const params = new URLSearchParams()
      let send = true
      if (this.validateEmail(this.email_address)) {
        if (!this.validateField(this.first_name)) {
          send = false
        }
        if (!this.validateField(this.last_name)) {
          send = false
        }
        if (!this.validateField(this.message)) {
          send = false
        }
        if (send) {
          params.append('first_name', this.first_name)
          params.append('last_name', this.last_name)
          params.append('phone', this.phone)
          params.append('email_address', this.email_address)
          params.append('message', this.message)
          this.$axios
            .post(
              'https://qopps-cms.rosegaar.work/wp-json/contact-form-7/v1/contact-forms/6/feedback',
              params,
              {
                'Content-Type': 'application/x-www-form-urlencoded'
              }
            )
            .then(() => {
              this.error_message = this.$t('form.thank_you')
              this.error_type = 'success'
              this.first_name = ''
              this.last_name = ''
              this.phone = ''
              this.email_address = ''
              this.message = ''
            })
            .catch(() => {
              this.error_message = this.$t('form.fail')
              this.error_type = 'error'
            })
        } else {
          this.error_message = this.$t('form.fail')
          this.error_type = 'error'
        }
      } else {
        this.error_message = this.$t('form.false_email')
        this.error_type = 'error'
      }
    }
  }
}
</script>
