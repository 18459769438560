<template>
  <header>
    <section id="header_bar">
      <div class="container">
        <div class="flex flex-row">
          <div class="w-3/5 tablet:w-2/5">
            <nuxt-link to="/" class="hidden tablet:block"
              ><img src="/images/logo-desktop.svg"
            /></nuxt-link>
            <nuxt-link to="/" class="block tablet:hidden"
              ><img src="/images/logo.svg"
            /></nuxt-link>
          </div>
          <div class="w-2/5 tablet:w-3/5">
            <div
              v-bind:class="{ open: showMenu }"
              @click="changeMobilemenu"
              class="hamburgerLink"
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="main_menu" v-if="showMenu">
      <div class="container">
        <div class="flex flex-row">
          <div class="w-full tablet:w-3/5">
            <ul class="big_menu">
              <li v-for="collection in collections" :key="collection.page.id">
                <nuxt-link
                  :to="
                    localePath({
                      name: 'collection-products',
                      params: {
                        products: collection.page.slug
                      }
                    })
                  "
                  v-html="collection.page.title"
                />
              </li>
            </ul>
          </div>
          <div class="w-full tablet:w-2/5">
            <ul class="small_menu">
              <li>
                <nuxt-link
                  v-t="'components.menu.contact'"
                  :to="localePath('contact')"
                />
              </li>
              <li>
                <nuxt-link
                  v-t="'components.menu.social_media'"
                  :to="localePath('social-media')"
                />
              </li>
              <li>
                <nuxt-link
                  v-t="'components.menu.outlets'"
                  :to="localePath('outlets')"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </header>
</template>

<script>
export default {
  name: 'MenuComponent',

  data() {
    const collections = this.$store.getters.getCategories
    return {
      showMenu: false,
      collections
    }
  },
  watch: {
    $route(to, from) {
      this.showMenu = false
    }
  },
  created() {
    this.language = this.$i18n.locale
  },
  methods: {
    changeMobilemenu() {
      this.showMenu = !this.showMenu
    }
  }
}
</script>
