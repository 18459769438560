<template>
  <div ref="StoreFinderMap" class="StoreFinderMap" />
</template>

<script>
import MarkerClusterer from '@google/markerclustererplus'

export default {
  name: 'StoreFinderMap',
  props: {
    currentLocation: {
      default: () => ({}),
      type: Object
    },
    stores: {
      default: () => [],
      required: true,
      type: Array
    }
  },
  data() {
    return {
      google: undefined,
      geocoder: undefined,
      map: undefined
    }
  },
  watch: {
    currentLocation() {
      // Zoom to the nearest store relative
      // to the current location.
      const neareststore = this.stores[0]
      const { lat, lng } = neareststore.acf.address
      const latLng = new this.google.maps.LatLng(lat, lng)
      this.geocoder.geocode({ latLng }, (results, status) => {
        if (status !== 'OK' || !results[0]) return

        this.map.setCenter(results[0].geometry.location)
        this.map.fitBounds(results[0].geometry.viewport)
      })
    }
  },
  async mounted() {
    try {
      this.google = await this.$gmapsInit()
      this.geocoder = new this.google.maps.Geocoder()
      this.map = new this.google.maps.Map(this.$el)
      // Zoom to Europe / TODO Change to something else if needed like Netherlands
      this.geocoder.geocode({ address: 'Nederland' }, (results, status) => {
        if (status !== 'OK' || !results[0]) {
          throw new Error(status)
        }
        this.map.setCenter(results[0].geometry.location)
        this.map.fitBounds(results[0].geometry.viewport)
      })
      // Initialize and cluster markers / TODO determine zoom level
      const image = '/images/icons/pointer.png'
      const markers = this.stores.map((store) => {
        const destination = [
          `${store.acf.address.street_name} ${store.acf.address.street_number}`,
          `${store.acf.address.post_code} ${store.acf.address.city}`
        ].join(', ')
        const infoWindow = new this.google.maps.InfoWindow({
          content: `<strong>${store.title}</strong><br>${
            store.acf.address.street_name
          } ${store.acf.address.street_number}<br />${
            store.acf.address.post_code
          } ${store.acf.address.city}<br />${
            store.acf.address.country
          }<br><a href="https://www.google.com/maps/dir/?api=1&destination=${encodeURI(
            destination
          )}" target="_blank">${this.$t('components.stores.directions')}</a>`
        })
        const marker = new this.google.maps.Marker({
          position: {
            lat: store.acf.address.lat,
            lng: store.acf.address.lng
          },
          map: this.map,
          icon: image,
          title: store.title
        })
        // marker.addListener('click', () => markerClickHandler(marker))
        marker.addListener('click', function() {
          // this.map.setZoom(14)
          this.map.setCenter(marker.getPosition())
          infoWindow.open(this.map, marker)
        })
        return marker
      })
      // eslint-disable-next-line no-new,no-undef
      new MarkerClusterer(this.map, markers, {
        imagePath:
          'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
      })
    } catch (error) {
      // Implement your own error handling here.
      console.error(error)
    }
  }
}
</script>

<style lang="scss">
.StoreFinderMap {
  width: 100%;
  height: 100%;
  min-height: 60vh;
}
</style>
