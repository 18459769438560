<template>
  <div class="category product">
    <div class="category-content with-radius">
      <span
        v-if="productData.acf.is_new && productData.acf.is_new === '1'"
        class="isNew"
      >
        <span v-t="'components.products.new'" />
      </span>
      <nuxt-link
        :to="
          localePath({
            name: 'collection-products-slug',
            params: {
              products: category,
              slug: productData.slug
            }
          })
        "
      >
        <div class="relative w-full" style="height: 300px;">
          <img
            :src="productData.media.medium"
            :alt="productData.title"
            data-object-fit="contain"
          /></div
      ></nuxt-link>
      <div class="category-text relative z-10">
        <h4 v-html="productData.title" />
        <nuxt-link
          :to="
            localePath({
              name: 'collection-products-slug',
              params: {
                products: category,
                slug: productData.slug
              }
            })
          "
          v-t="'components.products.more_information'"
          class="button button-green"
        />
        <nuxt-link
          :to="
            localePath({
              name: 'outlets',
              query: { product: productData.slug }
            })
          "
          class="button button-transparent"
          ><span v-t="'components.products.find_outlet'"/>
          <i class="fas fa-search fa-flip-horizontal float-right mt-2"
        /></nuxt-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CatalogProduct',
  props: {
    productData: {
      type: Object,
      default: () => {},
      required: true
    },
    category: {
      type: String,
      default: '',
      required: true
    }
  }
}
</script>
