<template>
  <main>
    <section id="product-detail">
      <div class="container wide">
        <div class="flex flex-row">
          <div class="w-full tablet:w-3/5 product-images px-2">
            <div class="flex flex-row">
              <div class="w-full px-2">
                <div class="product-image">
                  <div class="product-image-content with-radius">
                    <span
                      v-if="
                        activeProduct.acf.is_new &&
                          activeProduct.acf.is_new === '1'
                      "
                      class="isNew"
                    >
                      <span v-t="'components.products.new'" />
                    </span>
                    <div class="relative w-full" style="height: 450px;">
                      <img
                        :src="images[0]"
                        :alt="activeProduct.title"
                        @click="setIndex(0)"
                        data-object-fit="contain"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-row hidden tablet:flex">
              <div
                v-if="images.length > 1"
                v-for="(image, index) in images.slice(1, images.length)"
                :key="index"
                @click="setIndex(index)"
                class="w-full tablet:w-1/3 px-2"
              >
                <div class="product-image">
                  <div class="product-image-content with-radius">
                    <div class="relative w-full" style="height: 150px;">
                      <img
                        :src="image"
                        :alt="activeProduct.title"
                        class="thumb"
                        data-object-fit="contain"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <client-only>
              <gallery :images="images" :index="index" @close="index = null" />
            </client-only>
          </div>

          <div class="w-full tablet:w-2/5">
            <div class="product-detail-info">
              <h5 v-if="activeProduct.acf.sku">
                {{ $t('components.products.sku') }} -
                {{ activeProduct.acf.sku }}
              </h5>
              <h1 v-html="activeProduct.title" class="xl" />
              <ul v-if="activeProduct.acf.specifications" class="fa-ul">
                <li
                  v-for="(specification, index) in activeProduct.acf
                    .specifications"
                  :key="index"
                >
                  <span class="fa-li"><i class="fas fa-check"/></span
                  >{{ specification.specification }}
                </li>
              </ul>
              <div
                v-scroll-to="'#product_description'"
                v-t="'components.products.more_information'"
                class="cursor-pointer mt-2 mb-4"
              />
              <div class="buttons">
                <nuxt-link
                  :to="
                    localePath({
                      name: 'outlets',
                      query: { product: activeProduct.slug }
                    })
                  "
                  class="button button-orange"
                  ><span v-t="'components.products.find_outlet'"/>
                  <i
                    class="fas fa-search fa-flip-horizontal ml-8 mt-2"/></nuxt-link
                ><br />
                <nuxt-link
                  :to="
                    localePath({
                      name: 'contact',
                      query: { product: activeProduct.slug }
                    })
                  "
                  v-t="'components.products.question'"
                  class="button button-transparent-gray"
                />
              </div>
            </div>
          </div>
          <div
            id="product_description"
            v-if="activeProduct.content"
            class="w-full tablet:w-3/4 tablet:mx-auto px-4"
          >
            <h2 class="text-green lowercase text-center">
              &bull; {{ $t('components.products.description') }} &bull;
            </h2>
            <div v-html="activeProduct.content" />
          </div>
        </div>
      </div>
    </section>

    <section id="categories" class="categories my-16">
      <div class="container">
        <div class="flex flex-row">
          <div class="w-full text-center mb-6">
            <h2 class="text-green">
              &bull; {{ $t('components.products.linked_products') }} &bull;
            </h2>
          </div>
        </div>
        <div class="flex flex-row">
          <div
            v-for="(product, index) in childProducts.slice(0, 3)"
            :key="index"
            class="w-full tablet:w-1/3 px-4"
          >
            <catalog-product
              :product-data="product"
              :category="activeCategory.page.slug"
            />
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import CatalogProduct from '../../../../components/catalog/Product'
export default {
  name: 'Product',
  components: { CatalogProduct },
  head() {
    const title =
      this.activeProduct.yoast.yoast_wpseo_title === ''
        ? this.activeProduct.title + ' • QOPPS • outdoor furniture •'
        : this.activeProduct.yoast.yoast_wpseo_title
    const description =
      this.activeProduct.yoast.yoast_wpseo_metadesc === ''
        ? this.activeProduct.excerpt.replace(/<(?:.|\n)*?>/gm, '')
        : this.activeProduct.yoast.yoast_wpseo_metadesc

    return {
      title,
      titleTemplate: title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: description
        },
        { property: 'og:locale', content: 'nl_NL' },
        { property: 'og:type', content: 'page' },
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
        {
          property: 'og:url',
          content: 'https://www.qopps.nl' + this.$route.fullPath
        },
        { property: 'og:site_name', content: 'QOPPS' },
        {
          property: 'og:article:published_time',
          content: this.activeProduct.date
        },
        {
          property: 'og:article:modified_time',
          content: this.activeProduct.date_modified
        },
        {
          property: 'og:article:updated_time',
          content: this.activeProduct.date_modified
        },
        {
          property: 'og:image',
          content: this.activeProduct.media
            ? this.activeProduct.media.thumbnail
            : 'https://www.qopps.nl/images/logo2x.png'
        },
        {
          property: 'og:image:width',
          content: this.activeProduct.media ? 500 : 351
        },
        {
          property: 'og:image:height',
          content: this.activeProduct.media ? 500 : 65
        },
        { property: 'twitter:card', content: 'summary_large_image' },
        { property: 'twitter:description', content: description },
        { property: 'twitter:title', content: title },
        {
          property: 'twitter:image',
          content: this.activeProduct.media
            ? this.activeProduct.media.thumbnail
            : 'https://www.qopps.nl/images/logo2x.png'
        }
      ],
      __dangerouslyDisableSanitizers: ['script'],
      script: [
        {
          innerHTML: JSON.stringify(this.structuredData),
          type: 'application/ld+json'
        }
      ]
    }
  },
  data() {
    return {}
  },
  asyncData({ store, params, route }) {
    const products = store.getters.getProducts
    let activeProduct = {}
    products.map((product) => {
      if (product.slug === params.slug) {
        activeProduct = product
      }
    })
    const images = []
    activeProduct.acf.gallery.map((image) => {
      images.push(image.url)
    })
    if (images.length === 0) {
      images.push('/images/no-img.jpg')
    }
    const categories = store.getters.getCategories
    let activeCategory = {}
    categories.map((category) => {
      if (category.page.slug === params.products) {
        activeCategory = category
      }
    })
    const childProducts = []
    activeCategory.products.map((product) => {
      if (product.slug !== params.slug) {
        childProducts.push(product)
      }
    })
    return {
      activeCategory,
      activeProduct,
      childProducts,
      images,
      structuredData: {
        '@context': 'https://schema.org/',
        '@type': 'Product',
        name: activeProduct.title,
        image: images,
        description: activeProduct.content,
        sku: activeProduct.acf.sku,
        mpn: activeProduct.acf.sku,
        brand: {
          '@type': 'Brand',
          name: 'Qopps'
        },
        offers: {
          '@type': 'Offer',
          availability: 'https://schema.org/InStock',
          price: activeProduct.acf.price ? activeProduct.acf.price : 0,
          priceCurrency: 'EUR'
        }
      },
      index: null
    }
  },
  mounted() {
    if (process.browser) {
      this.$gtag('event', 'your_event', {
        // page_title: this.product.title,
        page_path: this.$route.fullPath
      })
    }
  },
  methods: {
    setIndex(index) {
      this.index = index
    }
  }
}
</script>

<style>
.blueimp-gallery,
.blueimp-gallery > .slides > .slide > .slide-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -ms-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
  backface-visibility: hidden;
  -moz-backface-visibility: hidden;
}
.blueimp-gallery > .slides > .slide > .slide-content {
  margin: auto;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  opacity: 1;
}
.blueimp-gallery {
  position: fixed;
  z-index: 999999;
  overflow: hidden;
  background: #fff;
  opacity: 0;
  display: none;
  direction: ltr;
  -ms-touch-action: none;
  touch-action: none;
}
.blueimp-gallery-carousel {
  position: relative;
  z-index: auto;
  margin: 1em auto;
  padding-bottom: 56.25%;
  box-shadow: 0 0 10px #000;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}
.blueimp-gallery-display {
  display: block;
  opacity: 1;
}
.blueimp-gallery > .slides {
  position: relative;
  height: 100%;
  overflow: hidden;
}
.blueimp-gallery-carousel > .slides {
  position: absolute;
}
.blueimp-gallery > .slides > .slide {
  position: relative;
  float: left;
  height: 100%;
  text-align: center;
  -webkit-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  -moz-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  -ms-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}
.blueimp-gallery > .slides > .slide-loading {
  /*background: url(../img/loading.gif) center no-repeat;*/
  background-size: 64px 64px;
}
.blueimp-gallery > .slides > .slide-loading > .slide-content {
  opacity: 0;
}
.blueimp-gallery > .slides > .slide-error {
  /*background: url(../img/error.png) center no-repeat;*/
}
.blueimp-gallery > .slides > .slide-error > .slide-content {
  display: none;
}
.blueimp-gallery > .next,
.blueimp-gallery > .prev {
  position: absolute;
  top: 50%;
  left: 15px;
  width: 40px;
  height: 40px;
  margin-top: -23px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 60px;
  font-weight: 100;
  line-height: 30px;
  color: #586666;
  text-decoration: none;
  text-align: center;
  background: #fff;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  border: 3px solid #586666;
  -webkit-border-radius: 23px;
  -moz-border-radius: 23px;
  border-radius: 23px;
  opacity: 0.5;
  cursor: pointer;
  display: none;
}
.blueimp-gallery > .next {
  left: auto;
  right: 15px;
}
.blueimp-gallery > .close,
.blueimp-gallery > .title {
  position: absolute;
  top: 15px;
  left: 15px;
  margin: 0 40px 0 0;
  font-size: 20px;
  line-height: 30px;
  color: #586666;
  opacity: 0.8;
  display: none;
}
.blueimp-gallery > .close {
  padding: 15px;
  right: 15px;
  left: auto;
  margin: -15px;
  font-size: 80px;
  text-decoration: none;
  cursor: pointer;
}
.blueimp-gallery > .play-pause {
  position: absolute;
  right: 15px;
  bottom: 15px;
  width: 15px;
  height: 15px;
  /*background: url(../img/play-pause.png) 0 0 no-repeat;*/
  cursor: pointer;
  opacity: 0.5;
  display: none;
}
.blueimp-gallery-playing > .play-pause {
  background-position: -15px 0;
}
.blueimp-gallery-controls > .close,
.blueimp-gallery-controls > .next,
.blueimp-gallery-controls > .play-pause,
.blueimp-gallery-controls > .prev,
.blueimp-gallery-controls > .title {
  display: block;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
}
.blueimp-gallery-left > .prev,
.blueimp-gallery-right > .next,
.blueimp-gallery-single > .next,
.blueimp-gallery-single > .play-pause,
.blueimp-gallery-single > .prev {
  display: none;
}
.blueimp-gallery > .close,
.blueimp-gallery > .next,
.blueimp-gallery > .play-pause,
.blueimp-gallery > .prev,
.blueimp-gallery > .slides > .slide > .slide-content {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.blueimp-gallery > .close:hover,
.blueimp-gallery > .next:hover,
.blueimp-gallery > .play-pause:hover,
.blueimp-gallery > .prev:hover,
.blueimp-gallery > .title:hover {
  color: #68715b;
  opacity: 1;
}
body:last-child .blueimp-gallery > .slides > .slide-error {
  /*background-image: url(../img/error.svg);*/
}
body:last-child .blueimp-gallery > .play-pause {
  width: 20px;
  height: 20px;
  background-size: 40px 20px;
  /*background-image: url(../img/play-pause.svg);*/
}
body:last-child .blueimp-gallery-playing > .play-pause {
  background-position: -20px 0;
}
* + html .blueimp-gallery > .slides > .slide {
  min-height: 300px;
}
* + html .blueimp-gallery > .slides > .slide > .slide-content {
  position: relative;
}
.blueimp-gallery > .indicator {
  position: absolute;
  top: auto;
  right: 15px;
  bottom: 15px;
  left: 15px;
  margin: 0 40px;
  padding: 0;
  list-style: none;
  text-align: center;
  line-height: 10px;
  display: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.blueimp-gallery > .indicator > li {
  display: inline-block;
  width: 9px;
  height: 9px;
  margin: 6px 3px 0 3px;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  border: 1px solid transparent;
  background: #ccc;
  background: rgba(255, 255, 255, 0.25) center no-repeat;
  border-radius: 5px;
  box-shadow: 0 0 2px #000;
  opacity: 0.5;
  cursor: pointer;
}
* + html .blueimp-gallery > .indicator > li {
  display: inline;
}
.blueimp-gallery > .indicator > .active,
.blueimp-gallery > .indicator > li:hover {
  background-color: #fff;
  border-color: #fff;
  opacity: 1;
}
.blueimp-gallery > .indicator > li:after {
  opacity: 0;
  display: block;
  position: absolute;
  content: '';
  top: -5em;
  width: 75px;
  height: 75px;
  transition: transform 0.6s ease-out, opacity 0.4s ease-out;
  transform: translateX(-50%) translateY(0) translateZ(0);
  pointer-events: none;
}
.blueimp-gallery > .indicator > li:hover:after {
  opacity: 1;
  border-radius: 50%;
  background: inherit;
  transform: translateX(-50%) translateY(-5px) translateZ(0);
}
.blueimp-gallery > .indicator > .active:after {
  display: none;
}
.blueimp-gallery-controls > .indicator {
  display: block;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
}
.blueimp-gallery-single > .indicator {
  display: none;
}
.blueimp-gallery > .slides > .slide > .video-content > img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  backface-visibility: hidden;
  -moz-backface-visibility: hidden;
}
.blueimp-gallery > .slides > .slide > .video-content > video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}
.blueimp-gallery > .slides > .slide > .video-content > iframe {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}
.blueimp-gallery > .slides > .slide > .video-playing > iframe {
  top: 0;
}
.blueimp-gallery > .slides > .slide > .video-content > a {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  margin: -64px auto 0;
  width: 128px;
  height: 128px;
  /*background: url(../img/video-play.png) center no-repeat;*/
  opacity: 0.8;
  cursor: pointer;
}
.blueimp-gallery > .slides > .slide > .video-playing > a,
.blueimp-gallery > .slides > .slide > .video-playing > img {
  display: none;
}
.blueimp-gallery > .slides > .slide > .video-playing > video {
  display: block;
}
.blueimp-gallery > .slides > .slide > .video-loading > a {
  /*background: url(../img/loading.gif) center no-repeat;*/
  background-size: 64px 64px;
}
* + html .blueimp-gallery > .slides > .slide > .video-content {
  height: 100%;
}
* + html .blueimp-gallery > .slides > .slide > .video-content > a {
  left: 50%;
  margin-left: -64px;
}
.blueimp-gallery > .slides > .slide > .video-content > a:hover {
  opacity: 1;
}
body:last-child
  .blueimp-gallery
  > .slides
  > .slide
  > .video-content:not(.video-loading)
  > a {
  /*background-image: url(../img/video-play.svg);*/
}
</style>
