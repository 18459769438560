<template>
  <div>
    <StoreFinderItem
      v-for="store in stores"
      :key="store.id"
      :address="store.acf.address"
      :name="store.title"
      :url="store.acf.url"
      :distance="store.distance"
      :products="store.acf.producten"
      class="StoreFinderList__item"
    />
  </div>
</template>

<script>
import StoreFinderItem from './StoreFinderItem.vue'

export default {
  name: 'StoreFinderList',
  components: {
    StoreFinderItem
  },
  props: {
    stores: {
      default: () => [],
      required: true,
      type: Array
    }
  }
}
</script>
