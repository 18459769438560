<template>
  <div class="StoreFinderItem">
    <address class="StoreFinderItem__section">
      <div class="StoreFinderItem__section">
        <div class="StoreFinderItem__headline">
          {{ name }}
        </div>
        {{ address.street_name }} {{ address.street_number }}<br />
        {{ address.post_code }}
        {{ address.city }}<br />
        {{ address.country }}
        <!--<hr />
        {{ products.length }}
        {{
          products.length === 1
            ? $t('components.stores.product')
            : $t('components.stores.products')
        }}-->
      </div>
      <div v-if="url" class="StoreFinderItem__section font-bold">
        <a :href="url" rel="nofollow" target="_blank">
          {{ $t('components.stores.website') }}
        </a>
      </div>
      <div class="StoreFinderItem__section font-bold">
        <a :href="directionsUrl" rel="nofollow" target="_blank">
          {{ $t('components.stores.directions') }}
          {{ distance !== '' ? '(' + distance + ' km)' : '' }}
        </a>
      </div>
    </address>
  </div>
</template>

<script>
export default {
  name: 'StoreFinderItem',
  props: {
    address: {
      default: () => ({}),
      required: true,
      type: Object
    },
    name: {
      default: '',
      required: true,
      type: String
    },
    distance: {
      default: '',
      required: false,
      type: String
    },
    url: {
      default: '',
      required: false,
      type: String
    },
    openingHours: {
      default: () => ({}),
      required: false,
      type: Object
    },
    products: {
      default: () => [],
      required: false,
      type: Array
    }
  },
  created() {
    // Create a Google Maps URL,
    // for directions to the shop.
    const url = 'https://www.google.com/maps/dir/?api=1'
    const destination = [
      `${this.address.street_name} ${this.address.street_number}`,
      `${this.address.post_code} ${this.address.city}`
    ].join(', ')
    this.directionsUrl = `${url}&destination=${encodeURI(destination)}`
  }
}
</script>

<style lang="scss">
.StoreFinderItem__headline {
  font-weight: bold;
}
</style>
