<template>
  <main>
    <section id="top_slider" class="visuals category-header">
      <div class="container wide">
        <div class="flex flex-row">
          <div class="w-full">
            <div class="slide">
              <img :src="page.acf.header_image.sizes['2048x2048']" :alt="page.title" />

              <div class="big-button right bottom negative orange no-hover">
                <div>
                  <span
                    v-t="'components.stores.header'"
                    class="big-text smaller"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="stores">
      <div class="container">
        <StoreFinder
          v-if="stores.length"
          :stores="stores"
          class="App__store-finder"
        />
      </div>
    </section>
  </main>
</template>

<script>
import StoreFinder from '../components/storeFinder/StoreFinder'
import wordpress from '../service/wordpress'
export default {
  name: 'Verkooppunten',
  components: { StoreFinder },
  head() {
    const title =
      this.page.yoast.yoast_wpseo_title === ''
        ? this.page.title + ' • QOPPS • outdoor furniture •'
        : this.page.yoast.yoast_wpseo_title
    const description =
      this.page.yoast.yoast_wpseo_metadesc === ''
        ? this.page.excerpt.replace(/<(?:.|\n)*?>/gm, '')
        : this.page.yoast.yoast_wpseo_metadesc

    return {
      title,
      titleTemplate: title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: description
        },
        { property: 'og:locale', content: 'nl_NL' },
        { property: 'og:type', content: 'page' },
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
        {
          property: 'og:url',
          content: 'https://www.qopps.nl' + this.$route.fullPath
        },
        { property: 'og:site_name', content: 'QOPPS' },
        {
          property: 'og:article:published_time',
          content: this.page.date
        },
        {
          property: 'og:article:modified_time',
          content: this.page.date_modified
        },
        {
          property: 'og:article:updated_time',
          content: this.page.date_modified
        },
        {
          property: 'og:image',
          content: this.page.acf.header_image
            ? this.page.acf.header_image.sizes.medium
            : 'https://www.qopps.nl/images/logo2x.png'
        },
        {
          property: 'og:image:width',
          content: this.page.acf.header_image
            ? this.page.acf.header_image.sizes['medium-width']
            : 351
        },
        {
          property: 'og:image:height',
          content: this.page.acf.header_image
            ? this.page.acf.header_image.sizes['medium-height']
            : 65
        },
        { property: 'twitter:card', content: 'summary_large_image' },
        { property: 'twitter:description', content: description },
        { property: 'twitter:title', content: title },
        {
          property: 'twitter:image',
          content: this.page.acf.header_image
            ? this.page.acf.header_image.sizes.medium
            : 'https://www.qopps.nl/images/logo.png'
        }
      ]
    }
  },
  data() {
    return {
      stores: []
    }
  },
  async asyncData() {
    const [page] = await Promise.all([wordpress.get(`page/verkooppunten`)])
    return {
      page: page.data
    }
  },
  created() {
    this.fetchStores()
  },
  mounted() {
    if (process.browser) {
      this.$gtag('event', 'your_event', {
        // page_title: this.product.title,
        page_path: this.$route.fullPath
      })
    }
  },
  methods: {
    async fetchStores() {
      // TODO stores ophalen uit Wordpress, dan ook async method van maken
      const json = await wordpress.get('outlets')
      this.stores = json.data
    }
  }
}
</script>
