<template>
  <section id="categories" class="categories my-24">
    <div class="container wide">
      <div class="flex flex-row">
        <div
          v-for="category in categories"
          :key="category.page.id"
          class="w-full tablet:w-1/3 px-4"
        >
          <div class="category">
            <nuxt-link
              :to="
                localePath({
                  name: 'collection-products',
                  params: {
                    products: category.page.slug
                  }
                })
              "
              class="category-content"
            >
              <img
                :src="category.page.media.thumbnail"
                :alt="category.page.title"
              />
              <h3 v-html="category.page.title" />
            </nuxt-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'CatalogCategories',
  props: {
    shrink: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    let categories = this.$store.getters.getCategories
    if (this.shrink) {
      categories = categories.slice(0, 3)
    }
    return {
      categories
    }
  }
}
</script>
