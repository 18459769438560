<template>
  <section id="distributors" class="my-24">
    <div class="container">
      <div class="flex flex-row">
        <div class="w-full tablet:w-5/6 tablet:mx-auto">
          <div class="flex flex-row relative">
            <div class="w-full tablet:w-2/3">
              <div class="circle circle-orange">
                <div class="text-content">
                  <h3 v-t="'components.stores.header'" class="xl" />
                  <div class="mt-4">
                    <nuxt-link
                      :to="localePath('outlets')"
                      class="block w-full button button-white-border"
                      type="submit"
                    >
                      {{ $t('components.stores.button') }}
                      <i class="fas fa-search fa-flip-horizontal" />
                    </nuxt-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full tablet:w-2/5 overlapping">
              <div class="circle circle-image relative z-30 mt-12">
                <img
                  :alt="$t('components.stores.button')"
                  src="/images/home-block.png"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'BarsOutlets'
}
</script>
