<template>
  <div>
    <menu-component />
    <nuxt />
    <footer-component />
  </div>
</template>

<script>
import menuComponent from '~/components/menuComponent.vue'
import footerComponent from '~/components/footerComponent.vue'

export default {
  components: {
    menuComponent,
    footerComponent
  }
}
</script>
