<template>
  <div class="StoreFinder">
    <div class="StoreFinder__search pb-12">
      <StoreFinderSearch @search="currentCoordinates = $event" />
    </div>
    <div class="StoreFinder__grid flex flex-wrap">
      <div class="w-full tablet:w-1/4 px-4">
        <StoreFinderList :stores="storesOrderedByDistance" />
      </div>
      <div class="w-full tablet:w-3/4 px-4">
        <div class="StoreFinder__map-wrap">
          <StoreFinderMap
            :stores="storesOrderedByDistance"
            :current-location="currentCoordinates"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { convertUnit, orderByDistance } from 'geolib'
import StoreFinderList from './StoreFinderList.vue'
import StoreFinderMap from './StoreFinderMap.vue'
import StoreFinderSearch from './StoreFinderSearch.vue'

export default {
  name: 'StoreFinder',
  components: {
    StoreFinderList,
    StoreFinderMap,
    StoreFinderSearch
  },
  props: {
    stores: {
      default: () => [],
      required: true,
      type: Array
    }
  },
  data() {
    return {
      currentCoordinates: null
    }
  },
  computed: {
    storeCoordinates() {
      return this.stores.map((store) => ({
        latitude: store.acf.address.lat,
        longitude: store.acf.address.lng
      }))
    },
    storesOrderedByDistance() {
      if (!this.currentCoordinates) return this.stores
      const orderAndDistance = orderByDistance(
        this.currentCoordinates,
        this.storeCoordinates
      )
      return orderAndDistance.map(({ distance, key }) => ({
        ...this.stores[key],
        distance: convertUnit('km', distance, 1)
      }))
    }
  }
}
</script>
