var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[_c('section',{attrs:{"id":"product-detail"}},[_c('div',{staticClass:"container wide"},[_c('div',{staticClass:"flex flex-row"},[_c('div',{staticClass:"w-full tablet:w-3/5 product-images px-2"},[_c('div',{staticClass:"flex flex-row"},[_c('div',{staticClass:"w-full px-2"},[_c('div',{staticClass:"product-image"},[_c('div',{staticClass:"product-image-content with-radius"},[(
                      _vm.activeProduct.acf.is_new &&
                        _vm.activeProduct.acf.is_new === '1'
                    )?_c('span',{staticClass:"isNew"},[_c('span',{directives:[{name:"t",rawName:"v-t",value:('components.products.new'),expression:"'components.products.new'"}]})]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"relative w-full",staticStyle:{"height":"450px"}},[_c('img',{attrs:{"src":_vm.images[0],"alt":_vm.activeProduct.title,"data-object-fit":"contain"},on:{"click":function($event){return _vm.setIndex(0)}}})])])])])]),_vm._v(" "),_c('div',{staticClass:"flex flex-row hidden tablet:flex"},_vm._l((_vm.images.slice(1, _vm.images.length)),function(image,index){return (_vm.images.length > 1)?_c('div',{key:index,staticClass:"w-full tablet:w-1/3 px-2",on:{"click":function($event){return _vm.setIndex(index)}}},[_c('div',{staticClass:"product-image"},[_c('div',{staticClass:"product-image-content with-radius"},[_c('div',{staticClass:"relative w-full",staticStyle:{"height":"150px"}},[_c('img',{staticClass:"thumb",attrs:{"src":image,"alt":_vm.activeProduct.title,"data-object-fit":"contain"}})])])])]):_vm._e()}),0),_vm._v(" "),_c('client-only',[_c('gallery',{attrs:{"images":_vm.images,"index":_vm.index},on:{"close":function($event){_vm.index = null}}})],1)],1),_vm._v(" "),_c('div',{staticClass:"w-full tablet:w-2/5"},[_c('div',{staticClass:"product-detail-info"},[(_vm.activeProduct.acf.sku)?_c('h5',[_vm._v("\n              "+_vm._s(_vm.$t('components.products.sku'))+" -\n              "+_vm._s(_vm.activeProduct.acf.sku)+"\n            ")]):_vm._e(),_vm._v(" "),_c('h1',{staticClass:"xl",domProps:{"innerHTML":_vm._s(_vm.activeProduct.title)}}),_vm._v(" "),(_vm.activeProduct.acf.specifications)?_c('ul',{staticClass:"fa-ul"},_vm._l((_vm.activeProduct.acf
                  .specifications),function(specification,index){return _c('li',{key:index},[_vm._m(0,true),_vm._v(_vm._s(specification.specification)+"\n              ")])}),0):_vm._e(),_vm._v(" "),_c('div',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:('#product_description'),expression:"'#product_description'"},{name:"t",rawName:"v-t",value:('components.products.more_information'),expression:"'components.products.more_information'"}],staticClass:"cursor-pointer mt-2 mb-4"}),_vm._v(" "),_c('div',{staticClass:"buttons"},[_c('nuxt-link',{staticClass:"button button-orange",attrs:{"to":_vm.localePath({
                    name: 'outlets',
                    query: { product: _vm.activeProduct.slug }
                  })}},[_c('span',{directives:[{name:"t",rawName:"v-t",value:('components.products.find_outlet'),expression:"'components.products.find_outlet'"}]}),_vm._v(" "),_c('i',{staticClass:"fas fa-search fa-flip-horizontal ml-8 mt-2"})]),_c('br'),_vm._v(" "),_c('nuxt-link',{directives:[{name:"t",rawName:"v-t",value:('components.products.question'),expression:"'components.products.question'"}],staticClass:"button button-transparent-gray",attrs:{"to":_vm.localePath({
                    name: 'contact',
                    query: { product: _vm.activeProduct.slug }
                  })}})],1)])]),_vm._v(" "),(_vm.activeProduct.content)?_c('div',{staticClass:"w-full tablet:w-3/4 tablet:mx-auto px-4",attrs:{"id":"product_description"}},[_c('h2',{staticClass:"text-green lowercase text-center"},[_vm._v("\n            • "+_vm._s(_vm.$t('components.products.description'))+" •\n          ")]),_vm._v(" "),_c('div',{domProps:{"innerHTML":_vm._s(_vm.activeProduct.content)}})]):_vm._e()])])]),_vm._v(" "),_c('section',{staticClass:"categories my-16",attrs:{"id":"categories"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"flex flex-row"},[_c('div',{staticClass:"w-full text-center mb-6"},[_c('h2',{staticClass:"text-green"},[_vm._v("\n            • "+_vm._s(_vm.$t('components.products.linked_products'))+" •\n          ")])])]),_vm._v(" "),_c('div',{staticClass:"flex flex-row"},_vm._l((_vm.childProducts.slice(0, 3)),function(product,index){return _c('div',{key:index,staticClass:"w-full tablet:w-1/3 px-4"},[_c('catalog-product',{attrs:{"product-data":product,"category":_vm.activeCategory.page.slug}})],1)}),0)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"fa-li"},[_c('i',{staticClass:"fas fa-check"})])}]

export { render, staticRenderFns }