<template>
  <main>
    <section id="top_slider" class="visuals category-header">
      <div class="container wide">
        <div class="flex flex-row">
          <div class="w-full">
            <div class="slide">
              <img
                :src="activeCategory.page.acf.header_image.sizes['2048x2048']"
                :alt="activeCategory.page.acf.title"
              />

              <div class="big-button right bottom negative orange no-hover">
                <div>
                  <span
                    v-html="activeCategory.page.acf.sphere_text"
                    class="big-text"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="categories" class="categories my-24">
      <div class="container">
        <div class="flex flex-row">
          <client-only>
            <masonry
              :cols="{ default: 3, 768: 1 }"
              :gutter="0"
              class="laptop:-ml-4 flex flex-wrap px-4 tablet:px-0 w-full"
            >
              <div
                v-for="product in activeCategory.products"
                :key="product.id"
                class="w-full"
              >
                <catalog-product
                  :product-data="product"
                  :category="activeCategory.page.slug"
                />
              </div>
            </masonry>
          </client-only>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import CatalogProduct from '../../../components/catalog/Product'
export default {
  name: 'Catalogus',
  components: { CatalogProduct },
  head() {
    const title =
      this.activeCategory.page.yoast.yoast_wpseo_title === ''
        ? this.activeCategory.page.title + ' • QOPPS • outdoor furniture •'
        : this.activeCategory.page.yoast.yoast_wpseo_title
    const description =
      this.activeCategory.page.yoast.yoast_wpseo_metadesc === ''
        ? this.activeCategory.page.content.replace(/<(?:.|\n)*?>/gm, '')
        : this.activeCategory.page.yoast.yoast_wpseo_metadesc

    return {
      title,
      titleTemplate: title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: description
        },
        { property: 'og:locale', content: 'nl_NL' },
        { property: 'og:type', content: 'page' },
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
        {
          property: 'og:url',
          content: 'https://www.qopps.nl' + this.$route.fullPath
        },
        { property: 'og:site_name', content: 'QOPPS' },
        {
          property: 'og:article:published_time',
          content: this.activeCategory.products[0].date
        },
        {
          property: 'og:article:modified_time',
          content: this.activeCategory.products[0].date_modified
        },
        {
          property: 'og:article:updated_time',
          content: this.activeCategory.products[0].date_modified
        },
        {
          property: 'og:image',
          content: this.activeCategory.page.media
            ? this.activeCategory.page.media.thumbnail
            : 'https://www.qopps.nl/images/logo2x.png'
        },
        {
          property: 'og:image:width',
          content: this.activeCategory.page.media ? 500 : 351
        },
        {
          property: 'og:image:height',
          content: this.activeCategory.page.media ? 500 : 65
        },
        { property: 'twitter:card', content: 'summary_large_image' },
        { property: 'twitter:description', content: description },
        { property: 'twitter:title', content: title },
        {
          property: 'twitter:image',
          content: this.activeCategory.page.media
            ? this.activeCategory.page.media.thumbnail
            : 'https://www.qopps.nl/images/logo2x.png'
        }
      ]
    }
  },
  data() {
    return {}
  },
  asyncData({ params, redirect, app, store }) {
    if (params.products) {
      const categories = store.getters.getCategories
      let activeCategory = {}
      categories.map((category) => {
        if (category.page.slug === params.products) {
          activeCategory = category
        }
      })
      return {
        activeCategory
      }
    } else {
      redirect(app.localePath('collection'))
    }
  },
  mounted() {
      console.log(this.activeCategory.page.acf);
    if (process.browser) {
      this.$gtag('event', 'your_event', {
        // page_title: this.product.title,
        page_path: this.$route.fullPath
      })
    }
  }
}
</script>
