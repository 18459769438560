<template>
  <footer>
    <section id="main_footer">
      <div class="container">
        <div class="flex flex-row">
          <div class="w-full tablet:w-1/2">
            <h4 v-t="'components.footer.follow_us'" />
            <p v-html="$t('components.footer.follow_us_text')" />
          </div>
          <div class="w-full tablet:w-1/4">
            <h4 v-t="'components.footer.collections'" />
            <ul class="lowercase">
              <li v-for="collection in collections" :key="collection.page.id">
                <nuxt-link
                  :to="
                    localePath({
                      name: 'collection-products',
                      params: {
                        products: collection.page.slug
                      }
                    })
                  "
                  v-html="collection.page.title"
                />
              </li>
            </ul>
          </div>
          <div class="w-full tablet:w-1/4">
            <h4 v-t="'components.footer.contact'" />
            <p v-html="$t('components.footer.contact_text')" />
            <p>
              <nuxt-link
                :to="localePath('outlets')"
                v-t="'components.footer.find_outlet'"
              />
            </p>
          </div>
        </div>
      </div>
    </section>
    <cookie-law
      :buttonText="$t('cookies.buttonText')"
      :buttonLink="localePath('privacy')"
      :buttonLinkText="$t('cookies.linkText')"
      :message="$t('cookies.message')"
      theme="blood-orange"
    />
  </footer>
</template>

<script>
export default {
  name: 'FooterComponent',
  data() {
    const collections = this.$store.getters.getCategories
    return {
      collections
    }
  }
}
</script>

<style scoped>
.Cookie--blood-orange .Cookie__button {
  background: #eb5d48;
}
.Cookie--blood-orange .Cookie__button:hover {
  background: #ce4f42;
}
</style>
