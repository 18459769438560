<template>
  <section class="circles spacing">
    <div class="container">
      <div class="flex flex-row relative">
        <div class="w-full tablet:w-1/2">
          <div class="circle circle-image relative z-30 tablet:mt-48">
            <img :src="circle.media.medium" :alt="circle.title" />
          </div>
        </div>
        <div class="w-full tablet:w-3/5 overlapping">
          <div
            :class="
              `circle circle-${
                circle.acf.content_color === 'green'
                  ? 'dark-' + circle.acf.content_color
                  : circle.acf.content_color
              }`
            "
          >
            <div class="text-content">
              <h1 v-html="circle.title" class="xl" />
              <div v-html="circle.content" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss">
main .circles .circle.circle-dark-green {
  @apply bg-green-dark text-white p-5;
  height: 660px;
}
</style>

<script>
export default {
  name: 'BarsCircle',
  data() {
    let circle = false
    const bars = this.$store.getters.getBars
    for (let i = 0; i < bars.length; i++) {
      if (bars[i].slug === 'oog-voor-detail') {
        circle = bars[i]
      }
    }
    return {
      circle
    }
  }
}
</script>
