<template>
  <div class="container pt-5 pb-3">
    <div class="row">
      <div class="col-12">
        <h1 class="large">Oeps</h1>
        <div v-if="error.statusCode === 404">
          <h3>Deze pagina bestaat niet</h3>
          <p>
            De opgevraagde pagina bestaat niet. U kunt bovenin zoeken naar het
            juiste product. Of naar onze
            <nuxt-link to="/">homepage</nuxt-link> gaan.
          </p>
        </div>
        <div v-else>
          <h3>Iets ging er niet goed</h3>
          <hr />
          <p>Error message: "{{ error.message }}"</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['error'],
  layout: 'blog' // you can set a custom layout for the error page
}
</script>
